// src/utils/fontawesome.js
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import {
  faCheckSquare,
  faCoffee,
  faTimesSquare,
  faHome,
} from "@fortawesome/free-solid-svg-icons";

import {
  faLinkedin,
  faVimeo,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

library.add(
  fab,
  faCheckSquare,
  faCoffee,
  faTimesSquare,
  faHome,
  faLinkedin,
  faVimeo,
  faYoutube
);
